import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalType } from '../../index';
import { Button, FlexBox, Typography } from '../index';
import { ConfirmationProps } from './types';

const Confirmation: FC<ConfirmationProps> = ({
    isOpened,
    setIsOpened,
    saveDataCallback,
    title,
    cancelBtnText,
    saveBtnText,
    type,
    children,
    submitBg,
    isDisabledSaveBtn,
    cancelCallback,
}) => {
    const { t } = useTranslation('common');

    const hideModalCallback = useCallback(() => {
        setIsOpened(false);
        cancelCallback?.();
    }, [setIsOpened, cancelCallback]);

    const bottomContent = useMemo(() => {
        return (
            <FlexBox gap={'1.143rem'} justify={'flex-end'}>
                <Button
                    width={'auto'}
                    borderWidth={'0.143rem'}
                    borderColor={'var(--primary-color)'}
                    pd={'0.571rem 1.143rem'}
                    br={'0.357rem'}
                    justify={'center'}
                    align={'center'}
                    background={'#fff'}
                    onClick={hideModalCallback}
                >
                    <Typography fontSize={'1.143rem'} fontWeight={700} color={'var(--primary-color)'}>
                        {cancelBtnText || t('confirmationCancelBtnText')}
                    </Typography>
                </Button>
                <Button
                    width={'auto'}
                    pd={'0.571rem 1.143rem'}
                    background={submitBg || 'var(--primary-color)'}
                    br={'0.357rem'}
                    justify={'center'}
                    align={'center'}
                    onClick={saveDataCallback}
                    disabled={isDisabledSaveBtn}
                >
                    <Typography fontSize={'1.143rem'} fontWeight={700} color={'#fff'}>
                        {saveBtnText || t('confirmationSaveBtnText')}
                    </Typography>
                </Button>
            </FlexBox>
        );
    }, [hideModalCallback, saveDataCallback, t, saveBtnText, cancelBtnText, isDisabledSaveBtn]);

    return (
        <Modal
            type={type || ModalType.Medium}
            open={isOpened}
            title={title}
            onClose={hideModalCallback}
            bottomContent={bottomContent}
        >
            <FlexBox pd={'1.714rem'} gap={'1.143rem'} dir={'column'}>
                {children}
            </FlexBox>
        </Modal>
    );
};
export default Confirmation;
