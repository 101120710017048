import { AxiosError, type AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair, QueryResult } from 'react-query';
import { useQueryCache, useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
    OrderSortingType,
    OrderSortDirection,
    PreparedFiltersType,
    Invoice,
    PurchaseGridResult,
    GboMenuType,
    VendorOrderStatusCode,
    UpdateOrderFoodDto,
    Order,
    CompanyOrder,
    PickUpDetails,
} from '../../../api/apiTypes/shopApiTypes';
import {
    getUserOrders,
    getOrder,
    getOrderExcelFile,
    getOrderInvoice,
    getOrdersByPeriodExcelFile,
    fetchPortalOrCompanyOrders,
    getVendorOrdersByPeriodExcelFile,
    cancelFoodOrder,
    updateFoodOrder,
    updateOrderPickUpDetails,
} from '../../../api/shopApi';
import { ShopOverviewView } from '../ShopAtWork';

export const OrderCacheKey = 'OrderCacheKey';
const OrdersCacheKey = 'OrdersCacheKey';
const OrderInvoiceCacheKey = 'OrderInvoiceCacheKey';
const OrderExcelFileCacheKey = 'OrderExcelFileCacheKey';

export const useGetInfiniteCompanyPurchases = (
    sortingType: OrderSortingType,
    sortDirection: OrderSortDirection | null,
    searchTerm = '',
    view: ShopOverviewView,
    filters: PreparedFiltersType[],
): InfiniteQueryResult<PurchaseGridResult, Error | AxiosError<string>> => {
    const numberOfOrders = 20;
    return useInfiniteQuery<PurchaseGridResult, Error | AxiosError<string>>(
        [OrdersCacheKey, sortingType, sortDirection, searchTerm, view, filters],
        async (_, sortingType, sortDirection, searchTerm, view, filters, page): Promise<PurchaseGridResult> => {
            if (view === ShopOverviewView.CompanyPurchases || view === ShopOverviewView.PortalPurchases) {
                const { data } = await fetchPortalOrCompanyOrders(
                    page ?? 0,
                    numberOfOrders,
                    sortingType,
                    sortDirection,
                    searchTerm,
                    view,
                    filters,
                );
                return data;
            }
            const { data } = await getUserOrders(
                page ?? 0,
                numberOfOrders,
                sortingType,
                sortDirection,
                searchTerm,
                view,
                filters,
            );
            return data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.data.length !== numberOfOrders) {
                    return false;
                }
                return allPages.length;
            },
        },
    );
};

export const useGetOrder = (orderId: string): QueryResult<CompanyOrder, string | AxiosError<string> | Error> => {
    return useQuery(
        [OrderCacheKey, orderId],
        async () => {
            const result = await getOrder(orderId);
            return result.data;
        },
        { enabled: !!orderId },
    );
};

export const useCancelFoodOrder = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    const queryClient = useQueryCache();

    return useMutation(async (id: string) => cancelFoodOrder(id), {
        onSuccess: async (_, orderId) => {
            await queryClient.invalidateQueries([OrderCacheKey, orderId]);
        },
    });
};
export const useUpdateFoodOrder = (): MutationResultPair<
    Order,
    string | AxiosError<string> | Error,
    UpdateOrderFoodDto,
    never
> => {
    const queryClient = useQueryCache();

    return useMutation(
        async (data) => {
            const response = await updateFoodOrder(data);
            return response.data;
        },
        {
            onSuccess: async (_, { orderId }) => {
                await queryClient.invalidateQueries([OrderCacheKey, orderId], { refetchInactive: true });
            },
        },
    );
};

export const useGetOrderInvoice = (orderId: string): QueryResult<Invoice, string | AxiosError<string> | Error> => {
    return useQuery([OrderInvoiceCacheKey, orderId], async () => {
        const result = await getOrderInvoice(orderId);
        return result.data;
    });
};

export const useGetOrderExcelFile = (
    orderId: string,
    orderNumber = 0,
): QueryResult<Blob, string | AxiosError<string> | Error> => {
    return useQuery(
        [OrderExcelFileCacheKey, orderId],
        async () => {
            const result = await getOrderExcelFile(orderId);
            return result.data;
        },
        {
            enabled: false,
            onSuccess: (file) => {
                const url = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Order_${orderNumber}.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            },
        },
    );
};

export const useGetOrdersByPeriodExcelFile = (): MutationResultPair<
    AxiosResponse<Blob, string>,
    AxiosError<string>,
    [string, string, string[], string],
    never
> => {
    return useMutation(
        async ([dateFrom, dateTo, companies, ianaLocaleString]) => {
            return getOrdersByPeriodExcelFile(dateFrom, dateTo, companies, ianaLocaleString);
        },
        {
            onSuccess: (file) => {
                const url = window.URL.createObjectURL(new Blob([file.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Orders.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            },
        },
    );
};

export const useGetVendorOrdersByPeriodExcelFile = (): MutationResultPair<
    AxiosResponse<Blob, string>,
    AxiosError<string>,
    [string, string, string[], VendorOrderStatusCode[], GboMenuType[], string, string],
    never
> => {
    return useMutation(
        async ([dateFrom, dateTo, companies, statuses, types, ianaLocaleString, vendorId]) => {
            return getVendorOrdersByPeriodExcelFile(
                dateFrom,
                dateTo,
                companies,
                statuses,
                types,
                ianaLocaleString,
                vendorId,
            );
        },
        {
            onSuccess: (file) => {
                const url = window.URL.createObjectURL(new Blob([file.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Orders.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            },
        },
    );
};

export const useUpdateOrderPickDetails = (): MutationResultPair<
    AxiosResponse<void>,
    AxiosError<string> | Error,
    { orderId: string; pickUpDetails: PickUpDetails },
    never
> => {
    return useMutation(updateOrderPickUpDetails);
};
