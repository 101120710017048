import { FC, useMemo } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrderStatusOption, VendorOrderStatusOption } from '../../../../../api/apiTypes/shopApiTypes';
import { Loader } from '../../../../../common';
import { Dropdown } from '../../../../../components';
import { DropDownProps } from '../../../../../components/general/dropdown/DropDown';
import { FlexBox, Typography } from '../../../../../components/styleds';
import { useUpdateOrderStatus } from '../../../../Admin/KitchenAdmin/apiQueries/useUpdateOrders';
import { ShopOverviewView } from '../../../../ShopAtWork/ShopAtWork';
import { formatDateToOrderDetails } from '../../../helpers';
import OrderCard from '../components/OrderCard';
import OrderSectionItem from '../components/OrderSectionItem';
import { CHIP_BG_COLOR, CHIP_TEXT_COLOR, orderMenuTypeEN, orderMenuTypeNO } from './constants';
import { DetailsSectionProps } from './types';

const StyledDropdown = styled(({ ...props }: DropDownProps) => <Dropdown {...props} />)``;

const DetailsSection: FC<DetailsSectionProps> = ({ isLoadingOrder, order, view }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });
    const isEnglish = i18n.language === 'en';
    const orderMenuType = isEnglish ? orderMenuTypeEN : orderMenuTypeNO;
    const [updateOrder, { isLoading: isUpdateOrderLoading, isError: isUpdateOrderError }] = useUpdateOrderStatus();

    const { t: tCommon } = useTranslation('common');
    const updateOrderErrorMessage = isUpdateOrderError ? tCommon('somethingWentWrong') : undefined;

    const orderStatusOptions = useMemo(() => {
        return (Object.keys(VendorOrderStatusOption) as Array<keyof typeof VendorOrderStatusOption>).map((key) => ({
            value: key,
            id: key,
        }));
    }, []);

    const statusValue = useMemo(() => {
        if (!order) return null;
        return view === ShopOverviewView.CompanyPurchases && order.status === OrderStatusOption.Ordered ? (
            isUpdateOrderLoading ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <FlexBox width={'auto'}>
                    <StyledDropdown
                        error={updateOrderErrorMessage}
                        initialValue={order.status}
                        badgeOptions={Object.values(VendorOrderStatusOption)}
                        items={orderStatusOptions}
                        value={order.status}
                        onChange={(val: string) => {
                            updateOrder([[order.id], val]);
                        }}
                    />
                </FlexBox>
            )
        ) : (
            <FlexBox
                width={'auto'}
                align={'center'}
                justify={'center'}
                pd={'0.286rem 0.571rem'}
                br={'2.143rem'}
                background={CHIP_BG_COLOR[order.status]}
            >
                <Typography
                    fontSize={'0.857rem'}
                    fontWeight={600}
                    lineHeight={'1.143rem'}
                    lineClamp={'1'}
                    width={'auto'}
                    color={CHIP_TEXT_COLOR[order.status]}
                >
                    {order.status}
                </Typography>
            </FlexBox>
        );
    }, [order, view, isUpdateOrderLoading]);

    return (
        <OrderCard title={t('foodOrderOrderDetailsSectionTitle')} isSkeleton={isLoadingOrder}>
            <FlexBox dir={'column'} gap={'8px'}>
                <OrderSectionItem label={t('foodOrderOrderDetailsSectionOrdNr')} value={order?.orderNumber} />
                <OrderSectionItem label={t('foodOrderOrderDetailsSectionStatus')} value={statusValue} />
                <OrderSectionItem
                    label={t('foodOrderOrderDetailsSectionType')}
                    value={order?.menuType && orderMenuType[order.menuType]}
                />
                <OrderSectionItem
                    label={t('foodOrderOrderDetailsSectionOrderCreated')}
                    value={formatDateToOrderDetails(order?.createdDate)}
                />
                <OrderSectionItem
                    label={t('foodOrderOrderDetailsSectionOrderLastUpdate')}
                    value={formatDateToOrderDetails(order?.updatedDate)}
                />
                <OrderSectionItem label={t('foodOrderOrderDetailsSectionCanteen')} value={order?.canteenName} />
            </FlexBox>
        </OrderCard>
    );
};

export default DetailsSection;
