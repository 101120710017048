import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { usePortalConfig } from '../../common/apiQueries/useAppConfiguration';
import { SidebarMenuItem } from '../../components';

const Wayfinding: FC = () => {
    const { t } = useTranslation('common');
    const { wayfindingUrl } = usePortalConfig();

    const openExternalLink = useCallback(() => window.open(wayfindingUrl, '_blank'), [wayfindingUrl]);

    return <SidebarMenuItem icon="LocationIcon" text={t('leftMenuWayfinding')} onClick={openExternalLink} />;
};
export default Wayfinding;
