import React, { useEffect, useMemo, SyntheticEvent } from 'react';
import { isSameDay, isEqual } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon, Label } from 'semantic-ui-react';
import type { DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { getTimeOptions } from '../utils';

const TimeSelector = styled(Dropdown)`
    &&& {
        min-width: 6em;
        display: flex;
        padding: 0.25rem 1rem;
        align-items: center;
    }
`;

const ClockIcon = styled(Icon)`
    color: var(--text-medium-emphasis-color);
    && {
        height: revert;
    }
`;

const ValueSpan = styled.span`
    margin: initial 0.5rem;
`;

const OptionLabel = styled(Label)`
    &&&&& {
        margin: 0 1rem;
    }
`;

interface DropdownMenuProps {
    maxDropdownHeight?: string;
}

const DropdownMenu = styled(Dropdown.Menu)`
    &&&& {
        width: max-content;
        max-height: ${(props: DropdownMenuProps) => props.maxDropdownHeight};
    }
    &&&&&& > * {
        color: var(--text-high-emphasis-color);
    }
`;

interface TimeDropdownProps {
    value: Date | null;
    onChange: (newDate: Date) => void;
    earliestPossibleTime: Date;
    latestPossibleTime: Date;
    handleSetIsError?: (shouldShow: boolean) => void;
    isRefetching?: boolean;
    disabled?: boolean;
    clockIcon?: boolean;
    meetingStart?: Date;
    meetingEnd?: Date;
    noRightIcon?: boolean;
    customError?: boolean;
    maxDropdownHeight?: string;
}

const TimeDropdown: React.FC<React.PropsWithChildren<TimeDropdownProps>> = ({
    value,
    onChange,
    earliestPossibleTime: earliestTime,
    latestPossibleTime: latestTime,
    handleSetIsError = () => null,
    isRefetching = false,
    disabled = false,
    clockIcon = false,
    meetingStart,
    meetingEnd,
    noRightIcon = false,
    customError = false,
    maxDropdownHeight,
}: TimeDropdownProps) => {
    const timeOptions = useMemo(() => {
        return getTimeOptions(earliestTime, latestTime);
    }, [earliestTime, latestTime]);
    const { t } = useTranslation('Food', { keyPrefix: 'commonFood' });
    const optionsContainsValue =
        timeOptions.find((optionValue) => isEqual(optionValue, value ?? new Date())) !== undefined;

    useEffect(() => {
        if (disabled) return;
        if (!value) {
            handleSetIsError(false);
        } else if (timeOptions.length > 0 && !optionsContainsValue && isSameDay(timeOptions[0], value)) {
            handleSetIsError(true);
        } else {
            handleSetIsError(false);
        }
    }, [disabled, handleSetIsError, optionsContainsValue, timeOptions, value]);

    const handleTimeChange = (e: SyntheticEvent, { value }: DropdownProps) => {
        onChange(new Date(value as string));
    };

    const actualOptions = optionsContainsValue || !value ? timeOptions : [...timeOptions, value];
    const label =
        value &&
        (meetingStart && isEqual(value, meetingStart)
            ? t('meetingStart')
            : meetingEnd && isEqual(value, meetingEnd)
            ? t('meetingEnd')
            : '');
    return (
        <TimeSelector
            loading={isRefetching}
            options={actualOptions}
            value={value?.toISOString()}
            onChange={handleTimeChange}
            selection
            required
            error={!disabled && (!value || !optionsContainsValue || customError)}
            fluid
            disabled={disabled}
            trigger={
                <>
                    {clockIcon ? <ClockIcon name="clock" /> : null}
                    <ValueSpan>{value && getDeviceLanguageDateFormat(value, 'HH:mm')}</ValueSpan>
                    {label ? <OptionLabel>{label}</OptionLabel> : null}
                </>
            }
            {...(noRightIcon ? { icon: null } : {})}
        >
            <DropdownMenu maxDropdownHeight={maxDropdownHeight}>
                {timeOptions.map((item: Date) => {
                    const optionLabel =
                        meetingStart && isEqual(item, meetingStart)
                            ? t('meetingStart')
                            : meetingEnd && isEqual(item, meetingEnd)
                            ? t('meetingEnd')
                            : '';
                    return (
                        <Dropdown.Item
                            key={item.toISOString()}
                            value={item.toISOString()}
                            onClick={() => onChange(item)}
                            content={
                                <>
                                    {getDeviceLanguageDateFormat(item, 'HH:mm')}
                                    {optionLabel ? <OptionLabel>{optionLabel}</OptionLabel> : null}
                                </>
                            }
                        />
                    );
                })}
            </DropdownMenu>
        </TimeSelector>
    );
};

export default TimeDropdown;
