import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Vendor } from '../../../api/apiTypes/foodForMeetingsApiTypes';
import {
    CompanyOption,
    CompanyOrderV2,
    CreateProductDto,
    EmployeeOption,
    NewProductCategory,
    OrderStatusOption,
    PaymentOption,
    Product,
    ProductByIdsQueryParams,
    ProductCategory,
    ProductCategoryOptionWithCount,
    ProductCategoryV2,
    ProductCategoryWithSubCategories,
    ProductFilterTypes,
    ProductStatusOption,
    ProductTypeOption,
    ProductTypeOptionWithCount,
    UpdateProductCategoryDto,
    UpdateProductCategoryResponse,
    UpdateProductDto,
    UserVendors,
} from '../../../api/apiTypes/shopApiTypes';
import {
    getOrderFilterStatusOptions,
    getOrderFilterCompanyOptions,
    getOrderFilterEmployeeOptions,
    getOrderFilterPaymentOptions,
    getOrderFilterProductStatusOptions,
    getOrderFilterProductTypeOptions,
    getProductTypesByPortalIdWithCount,
    getProductCategoriesByProductIdWithCount,
    createProductCategory,
    removeProductCategoryById,
    updateProductCategoryById,
    getCategoriesByProductTypeId,
    createProduct,
    updateProduct,
    getProductCategories,
    getOrderFilterMenuTypeOptions,
    getProductsByIdsList,
    getAllVendorsForUser,
    hasExternalIdInCanteen,
    getAllOrdersByIds,
} from '../../../api/shopApi';
import Perm from '../../../common/perms';
import { useUserHasPerm } from '../../../utility';
import { FoodForMeetingsOrderListItem, OrderListForTimeNow } from '../../FoodForMeetings/contexts/types';
import { useInvalidateProduct } from './useShopProducts';

const ShopFilterMenuTypeCacheKey = 'ShopFilterMenuTypeCacheKey';
const ShopFilterStatusCacheKey = 'ShopFilterStatusCacheKey';
const ShopFilterCompanyCacheKey = 'ShopFilterCompanyCacheKey';
const ShopFilterEmployeeCacheKey = 'ShopFilterEmployeeCacheKey';
const ShopFilterPaymentCacheKey = 'ShopFilterPaymentCacheKey';
const ShopFilterProductTypesCacheKey = 'ShopFilterProductTypesCacheKey';
const ShopFilterProductStatusesCacheKey = 'ShopFilterProductStatusesCacheKey';
const ShopFilterProductWithCategoriesCacheKey = 'ShopFilterProductWithCategoriesCacheKey';
const ShopCategoriesCacheKey = 'ShopCategoriesCacheKey';
const ProductCategoryCacheKey = 'ProductCategoryCacheKey';
const UserAdmins = 'UserAdmins';
const HasExternalIdInCanteenKey = 'HasExternalIdInCanteenKey';
const OrdersByIdsList = 'OrdersByIdsList';

export const useGetShopFilterStatusOptions = (): QueryResult<
    OrderStatusOption[],
    string | AxiosError<string> | Error
> => {
    return useQuery([ShopFilterStatusCacheKey], async () => {
        const result = await getOrderFilterStatusOptions();
        return result.data;
    });
};

export const useGetShopFilterMenuTypeOptions = (): QueryResult<
    OrderStatusOption[],
    string | AxiosError<string> | Error
> => {
    const userCanUpdateMenu = useUserHasPerm(Perm.todaysMenuUpdate);
    const userCanUpdateMenuImage = useUserHasPerm(Perm.todaysMenuImageUpdate);
    const userHasKitchenPerm = userCanUpdateMenu || userCanUpdateMenuImage;

    return useQuery(
        [ShopFilterMenuTypeCacheKey],
        async () => {
            const result = await getOrderFilterMenuTypeOptions();
            return result.data;
        },
        {
            enabled: userHasKitchenPerm,
        },
    );
};

export const useGetShopFilterCompanyOptions = (): QueryResult<CompanyOption[], string | AxiosError<string> | Error> => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const userCanUpdateMenu = useUserHasPerm(Perm.todaysMenuUpdate);
    const userCanUpdateMenuImage = useUserHasPerm(Perm.todaysMenuImageUpdate);
    const userHasKitchenPerm = userCanUpdateMenu || userCanUpdateMenuImage;

    return useQuery(
        [ShopFilterCompanyCacheKey],
        async () => {
            const result = await getOrderFilterCompanyOptions();
            return result.data;
        },
        {
            enabled: userIsPortalAdmin || userHasKitchenPerm,
        },
    );
};

export const useGetShopFilterEmployeeOptions = (): QueryResult<
    EmployeeOption[],
    string | AxiosError<string> | Error
> => {
    const userHasAccess = useUserHasPerm(Perm.PortalAdmin) || useUserHasPerm(Perm.CompanyAdmin);
    return useQuery(
        [ShopFilterEmployeeCacheKey],
        async () => {
            const result = await getOrderFilterEmployeeOptions();
            return result.data;
        },
        {
            enabled: userHasAccess,
        },
    );
};

export const useGetShopFilterPaymentOptions = (): QueryResult<PaymentOption[], string | AxiosError<string> | Error> => {
    return useQuery([ShopFilterPaymentCacheKey], async () => {
        const result = await getOrderFilterPaymentOptions();
        return result.data;
    });
};

export const useGetShopFilterProductStatusOptions = (): QueryResult<
    ProductStatusOption[],
    string | AxiosError<string> | Error
> => {
    return useQuery([ShopFilterProductTypesCacheKey], async () => {
        const result = await getOrderFilterProductStatusOptions();
        return result.data;
    });
};

export const useGetShopFilterProductTypeOptions = (): QueryResult<
    ProductTypeOption[],
    string | AxiosError<string> | Error
> => {
    return useQuery([ShopFilterProductStatusesCacheKey], async () => {
        const result = await getOrderFilterProductTypeOptions();
        return result.data;
    });
};

export const useGetProductTypesByPortalIdWithCategories = (): QueryResult<
    ProductCategoryWithSubCategories[],
    string | AxiosError<string> | Error
> => {
    return useQuery([ShopFilterProductWithCategoriesCacheKey], async () => {
        const { data: productTypes }: AxiosResponse<ProductTypeOptionWithCount[]> =
            await getProductTypesByPortalIdWithCount();
        return await Promise.all(
            productTypes.map(async (productTypeElement) => {
                const { data: categories }: AxiosResponse<ProductCategoryOptionWithCount[]> =
                    await getProductCategoriesByProductIdWithCount(productTypeElement.id);
                return { ...productTypeElement, childElements: categories };
            }),
        );
    });
};

export const useGetProductCategoriesByFilters = (
    companyId: string,
    term: string,
    isDelivery?: boolean,
    showUnavailable?: boolean,
    filters?: ProductFilterTypes[] | null,
    selectedCanteen?: Vendor | null,
): QueryResult<ProductCategoryV2[], string | AxiosError<string> | Error> => {
    return useQuery(
        [ProductCategoryCacheKey, companyId, term, isDelivery, showUnavailable, filters, selectedCanteen],
        async () => {
            const data: AxiosResponse<ProductCategoryV2[]> = await getProductCategories(
                companyId,
                term,
                isDelivery,
                showUnavailable,
                filters,
            );
            return data.data.sort((a, b) => a.name.localeCompare(b.name));
        },
        {
            enabled: Boolean(companyId) && Boolean(selectedCanteen),
            refetchInterval: 1000 * 60 * 30,
            keepPreviousData: true,
        },
    );
};

export const useCreateProductCategory = (
    productTypeId: string | null,
): MutationResultPair<
    AxiosResponse<{ id: string; name: string }>,
    string | AxiosError<string> | Error,
    NewProductCategory,
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        (form) => {
            return createProductCategory(form);
        },
        {
            onSuccess: (response) => {
                cache.invalidateQueries(ShopCategoriesCacheKey);
                cache.setQueryData<ProductCategoryWithSubCategories[] | undefined>(
                    [ShopFilterProductWithCategoriesCacheKey],
                    (curr) => {
                        if (!curr || !productTypeId) return curr;
                        const { data: createdCategory } = response;
                        return curr.map((productType) => {
                            if (productType.id === productTypeId) {
                                return {
                                    ...productType,
                                    childElements: [
                                        ...productType.childElements,
                                        { ...createdCategory, countProducts: 0 },
                                    ],
                                };
                            }
                            return productType;
                        });
                    },
                );
            },
        },
    );
};

export const useRemoveProductCategoryById = (
    removeElementId: string | null,
): MutationResultPair<AxiosResponse<void>, string | AxiosError<string> | Error, string, never> => {
    const cache = useQueryCache();

    return useMutation(
        (id) => {
            return removeProductCategoryById(id);
        },
        {
            onSuccess: () => {
                cache.setQueryData<ProductCategoryWithSubCategories[] | undefined>(
                    [ShopFilterProductWithCategoriesCacheKey],
                    (curr) => {
                        if (!curr || !removeElementId) return curr;
                        return curr.map((productType) => {
                            const removeCategoryCandidate = productType.childElements.find(
                                (category) => category.id === removeElementId,
                            );
                            if (!removeCategoryCandidate) return productType;
                            return {
                                ...productType,
                                childElements: productType.childElements.filter(
                                    (category) => category.id !== removeElementId,
                                ),
                            };
                        });
                    },
                );
            },
        },
    );
};

export const useUpdateProductCategoryById = (): MutationResultPair<
    AxiosResponse<UpdateProductCategoryResponse>,
    string | AxiosError<string> | Error,
    UpdateProductCategoryDto,
    never
> => {
    return useMutation(({ name, id }) => {
        return updateProductCategoryById({ name, id });
    });
};

export const useGetShopCategoriesByProductTypeId = (
    productTypeId: string,
): QueryResult<ProductCategory[] | AxiosError<string> | Error> => {
    return useQuery(
        [ShopCategoriesCacheKey, productTypeId],
        async () => {
            const result = await getCategoriesByProductTypeId(productTypeId);
            return result.data;
        },
        {
            enabled: !!productTypeId,
        },
    );
};

export const useCreateProduct = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError<string | Error>,
    CreateProductDto,
    never
> => {
    return useMutation((form) => {
        return createProduct(form);
    });
};

export const useUpdateProduct = (): MutationResultPair<
    AxiosResponse<Product>,
    string | AxiosError<string | Error>,
    UpdateProductDto,
    never
> => {
    const invalidateProduct = useInvalidateProduct();

    return useMutation(
        (form) => {
            return updateProduct(form);
        },
        {
            onSuccess: ({ data }: AxiosResponse<Product>) => {
                invalidateProduct(data.id);
            },
        },
    );
};
export const useGetProductsByIdsList = (): MutationResultPair<
    FoodForMeetingsOrderListItem[],
    string | AxiosError<string> | Error,
    { list: OrderListForTimeNow[]; filters: ProductByIdsQueryParams },
    never
> => {
    return useMutation(async ({ list, filters }) => {
        const result = await getProductsByIdsList(
            list.map(({ product }) => product.productAvailability.productId),
            filters,
        );
        return result.data?.map((productResult) => ({
            product: productResult,
            quantity:
                list.find(
                    ({ product }) =>
                        product.productAvailability.productId === productResult.productAvailability.productId,
                )?.quantity || 1,
        }));
    });
};

export const useGetAllVendorsForUser = (enabled: boolean): QueryResult<UserVendors[] | AxiosError<string> | Error> =>
    useQuery(
        [UserAdmins, enabled],
        async () => {
            const result = await getAllVendorsForUser();
            return result.data || [];
        },
        { enabled },
    );

export const useHasExternalIdInCanteen = (
    canteenId: string,
    enabled: boolean,
): QueryResult<boolean | AxiosError<string> | Error> =>
    useQuery(
        [HasExternalIdInCanteenKey, canteenId],
        async () => {
            const result = await hasExternalIdInCanteen(canteenId);
            return result.data;
        },
        { enabled: enabled && !!canteenId },
    );

export const useGetAllOrdersByIds = (
    orderIdsList: string[],
    enabled: boolean,
): QueryResult<CompanyOrderV2[] | AxiosError<string> | Error> =>
    useQuery(
        [OrdersByIdsList, orderIdsList],
        async () => {
            const result = await getAllOrdersByIds(orderIdsList);
            return Array.isArray(result.data) ? result.data : [];
        },
        { enabled },
    );
