import { AxiosPromise, AxiosResponse } from 'axios';
import qs from 'qs';
import { ShopOverviewView } from '../modules/ShopAtWork/ShopAtWork';
import { getDeviceLanguageDateFormat } from '../utility/dateUtilities/getDeviceLanguageDateFormat';
import { ImageModel } from './apiTypes/cmsApiTypes';
import {
    OrderSortingType,
    OrderSortDirection,
    PreparedFiltersType,
    OrderStatusOption,
    CompanyOption,
    EmployeeOption,
    PaymentOption,
    CompanyOrder,
    CompanyActivationSettings,
    Product,
    ProductStatusOption,
    ProductTypeOption,
    Invoice,
    PurchaseGridResult,
    GeneralOrder,
    GuestsLunchFormInfo,
    ProductCategory,
    ProductTypeOptionWithCount,
    ProductCategoryOptionWithCount,
    NewProductCategory,
    UpdateProductCategoryDto,
    UpdateProductCategoryResponse,
    CreateProductDto,
    AllergenType,
    UpdateProductDto,
    LunchProduct,
    Order,
    GboDiscount,
    GboMenu,
    GboSettings,
    CompanySettings,
    ExternalCompanyPerPointOfSale,
    UserInvoiceSettings,
    VendorOrderStatusCode,
    UserOrderGridResult,
    GboMenuType,
    CanteenAvailability,
    AccessEntitiesType,
    ProductFilterTypes,
    ProductCategoryV2,
    DailyMenuUpdateType,
    DailyMenuType,
    VendorAvailabilityForOrdering,
    GetProductsParams,
    ExternalCompanyCard,
    UpdateOrderFoodDto,
    ProductByIdsQueryParams,
    ProductV2,
    UserVendors,
    MenuType,
    CompanyOrderV2,
    PickUpDetails,
} from './apiTypes/shopApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';
const time_format = 'yyyy-MM-dd';

const urlShop = process.env.REACT_APP_APIM_SHOP_URL;

export const getCompanyActivationSettings = (): AxiosPromise<CompanyActivationSettings> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/settings`;
    return http.get<CompanyActivationSettings>(url);
};

export const getCompanyActivationSettingsByPortalAdmin = (): AxiosPromise<boolean> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/settings/isPortalSettingsEnabled`;
    return http.get<boolean>(url);
};

export const activateShop = (form: CompanyActivationSettings): AxiosPromise<string> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/settings`;
    return http.post<CompanyActivationSettings, AxiosResponse<string>>(url, form, {});
};

export const getUserOrders = (
    page: number,
    pageSize: number,
    sortingType: OrderSortingType,
    sortDirection: OrderSortDirection | null,
    term: string,
    view: ShopOverviewView,
    filters: PreparedFiltersType[],
): AxiosPromise<PurchaseGridResult> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/user`;

    return http.get<PurchaseGridResult>(url, {
        params: {
            page,
            pageSize,
            sortDirection,
            sortingType,
            term,
            filters: JSON.stringify(filters.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) }))),
        },
    });
};

export const fetchPortalOrCompanyOrders = (
    page: number,
    pageSize: number,
    sortingType: OrderSortingType,
    sortDirection: OrderSortDirection | null,
    term: string,
    view: ShopOverviewView,
    filters: PreparedFiltersType[],
): AxiosPromise<PurchaseGridResult> => {
    let url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders`;
    if (view === ShopOverviewView.PortalPurchases) url = `${url}/portal`;
    else if (view === ShopOverviewView.CompanyPurchases) url = `${url}/company`;

    return http.post<PurchaseGridResult>(
        url,
        filters.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) })),
        {
            params: {
                page,
                pageSize,
                sortDirection,
                sortingType,
                term,
            },
        },
    );
};

export const getOrder = (orderId: string): AxiosPromise<CompanyOrder> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/${orderId}`;
    return http.get<CompanyOrder>(url);
};

export const cancelFoodOrder = (orderId: string): AxiosPromise<void> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/${orderId}`;
    return http.delete<void>(url);
};
export const updateFoodOrder = (data: UpdateOrderFoodDto): AxiosPromise<Order> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/update`;
    return http.patch<Order>(url, data);
};

export const getOrderInvoice = (orderId: string): AxiosPromise<Invoice> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/${orderId}/invoice`;
    return http.get<Invoice>(url);
};

export const getOrderExcelFile = (orderId: string): AxiosPromise<Blob> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/excel/${orderId}`;
    return http.get<Blob>(url, {
        responseType: 'blob',
    });
};

export const getOrdersByPeriodExcelFile = (
    dateFrom: string,
    dateTo: string,
    companies: string[],
    ianaLocaleString: string,
): AxiosPromise<Blob> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/excel/period`;

    return http.post<Blob>(
        url,
        { companies, dateFrom, dateTo, ianaLocaleString },
        {
            responseType: 'blob',
            timeout: 1000 * 60 * 60 * 24,
        },
    );
};

export const getVendorOrdersByPeriodExcelFile = (
    dateFrom: string,
    dateTo: string,
    companies: string[],
    statuses: VendorOrderStatusCode[],
    types: GboMenuType[],
    ianaLocaleString: string,
    vendorId: string,
): AxiosPromise<Blob> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/excel/vendor/${vendorId}`;

    return http.post<Blob>(
        url,
        { dateFrom, dateTo, companies, statuses, types, ianaLocaleString },
        {
            responseType: 'blob',
            timeout: 1000 * 60 * 60 * 24,
        },
    );
};

export const getOrderFilterMenuTypeOptions = (): AxiosPromise<OrderStatusOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/menuTypes`;
    return http.get<OrderStatusOption[]>(url);
};

export const getOrderFilterStatusOptions = (): AxiosPromise<OrderStatusOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/status`;
    return http.get<OrderStatusOption[]>(url);
};

export const getOrderFilterCompanyOptions = (): AxiosPromise<CompanyOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/companies`;
    return http.get<CompanyOption[]>(url);
};

export const getOrderFilterEmployeeOptions = (): AxiosPromise<EmployeeOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/users`;
    return http.get<EmployeeOption[]>(url);
};

export const getOrderFilterPaymentOptions = (): AxiosPromise<PaymentOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/payment`;
    return http.get<PaymentOption[]>(url);
};

export const getOrderFilterProductStatusOptions = (): AxiosPromise<ProductStatusOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/productStatuses`;
    return http.get<ProductStatusOption[]>(url);
};

export const getCategoriesByProductTypeId = (productTypeId: string): AxiosPromise<ProductCategory[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/type/${productTypeId}/categories`;
    return http.get<ProductCategory[]>(url);
};

export const getOrderFilterProductTypeOptions = (): AxiosPromise<ProductTypeOption[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/productTypes`;
    return http.get<ProductTypeOption[]>(url);
};

export function OrderLunch(canteenId: string): Promise<AxiosResponse<GeneralOrder>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/lunch/${canteenId}`;
    return http.post<GeneralOrder>(url);
}

export function getGuestLunchById(productId: string): Promise<AxiosResponse<Product>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/getGuestLunch/${productId}`;
    return http.get<Product>(url);
}

export function getOrderedLunch(): Promise<AxiosResponse<Order>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/orderedLunch`;
    return http.get<Order>(url);
}

export function AddGuestsToLunch(
    orderId: string,
    guestsData: GuestsLunchFormInfo,
): Promise<AxiosResponse<GeneralOrder>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/${orderId}/guest`;
    return http.post<GeneralOrder>(url, null, { params: guestsData });
}

export const getProducts = ({
    filters,
    orderDateTime,
    searchTerm,
    ...rest
}: GetProductsParams): AxiosPromise<Product[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/all`;

    const params = {
        ...rest,
        filters: JSON.stringify(filters.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) }))),
        term: searchTerm,
        ...(orderDateTime && { orderDateTime }),
    };

    return http.get<Product[]>(url, { params });
};

export const createProduct = (data: CreateProductDto): AxiosPromise<never> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/create`;
    return http.post(url, data);
};

export const updateProduct = ({ id, ...data }: UpdateProductDto): AxiosPromise<never> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/${id}`;
    return http.put(url, data);
};

export const getProductTypesByPortalIdWithCount = (): Promise<AxiosResponse<ProductTypeOptionWithCount[]>> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/types/count-products`;
    return http.get<ProductTypeOptionWithCount[]>(url);
};

export const getProductCategoriesByProductIdWithCount = (
    productTypeId: string,
): Promise<AxiosResponse<ProductCategoryOptionWithCount[]>> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/type/${productTypeId}/categories/products`;
    return http.get<ProductCategoryOptionWithCount[]>(url);
};

export const createProductCategory = (form: NewProductCategory): AxiosPromise<{ id: string; name: string }> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/type/category`;
    return http.post<NewProductCategory, AxiosResponse<{ id: string; name: string }>>(url, form);
};

export const getProductCategories = async (
    companyId: string,
    term: string,
    isDelivery?: boolean,
    showUnavailable?: boolean,
    filters?: ProductFilterTypes[] | null,
): Promise<AxiosResponse<ProductCategoryV2[]>> => {
    const portalId = getPortalId();
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${portalId}/category/company/${companyId}/categories/byFilters`;
    return http.get<ProductCategoryV2[]>(url, {
        params: {
            term,
            isDelivery,
            showUnavailable,
            filters: JSON.stringify(filters?.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) }))),
        },
    });
};

export const removeProductCategoryById = (id: string): AxiosPromise<void> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/type/category/${id}`;
    return http.delete(url);
};

export const updateProductCategoryById = ({
    name,
    id,
}: UpdateProductCategoryDto): AxiosPromise<UpdateProductCategoryResponse> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/category/type/category/${id}`;
    return http.patch(url, { name });
};

export const getAllergens = (): AxiosPromise<AllergenType[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/dropdown/allergens`;
    return http.get<AllergenType[]>(url);
};

export const getProductById = (id: string): AxiosPromise<Product> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/${id}`;
    return http.get(url);
};

export const removeProductById = (id: string): AxiosPromise<void> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/${id}`;
    return http.delete(url);
};

export const getProductLunch = (): AxiosPromise<LunchProduct[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/lunch`;
    return http.get(url);
};

export const getGboMenus = (): AxiosPromise<GboMenu[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/menu`;
    return http.get(url);
};

export const getGboDiscounts = (): AxiosPromise<GboDiscount[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/discounts`;
    return http.get(url);
};

export const getCompanyGboSettings = (companyId: string): AxiosPromise<GboSettings[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/settings/${companyId}`;
    return http.get(url);
};

export const updateCompanyGboSettings = (companyId: string, settings: GboSettings[]): AxiosPromise<void> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/settings/${companyId}`;
    return http.put(url, settings);
};

export const getIntegrationCompanies = (): AxiosPromise<ExternalCompanyPerPointOfSale[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/companies`;
    return http.get(url);
};

export const getCompanyCards = (companyId: string): AxiosPromise<ExternalCompanyCard[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/companyCards/${companyId}`;
    return http.get(url);
};

export const assignExternalCompaniesToTargetLifeAtWork = (
    companyId: string,
    companySettings: CompanySettings,
): AxiosPromise<CompanySettings> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/companies/${companyId}/assignExternal`;
    return http.put<string, AxiosResponse<CompanySettings>>(url, companySettings);
};

export const getCompanySettings = (companyId: string): AxiosPromise<CompanySettings> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/companies/${companyId}/externalIds`;
    return http.get(url);
};

export const getInvoicingSettingsByUserId = (userId: string): AxiosPromise<UserInvoiceSettings> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/users/${userId}/invoicingSettings`;
    return http.get(url);
};

export const getIsInvoicingEnabledByCompanyId = (companyId: string): AxiosPromise<boolean> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/integration/companies/${companyId}/isInvoicingEnabled`;
    return http.get(url);
};

export const updateInvoicingSettingsByUserId = (
    userId: string,
    invoicingSettings: UserInvoiceSettings,
): AxiosPromise<unknown> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/users/${userId}/invoicingSettings`;
    return http.put(url, invoicingSettings);
};

export function getAllUsersOrdersForKitchenAdminInPortal(
    page: number,
    pageSize: number,
    sortingType: OrderSortingType,
    sortDirection: OrderSortDirection | null,
    term: string,
    filters: PreparedFiltersType[],
    vendorId?: string,
): Promise<AxiosResponse<UserOrderGridResult>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/vendor/${vendorId}`;
    const formattedFilters = filters.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) }));
    return http.post(url, formattedFilters, {
        params: {
            page,
            pageSize,
            sortDirection,
            sortingType,
            term,
        },
    });
}

export const updateOrderStatus = (orderIds: string[], status: VendorOrderStatusCode): AxiosPromise<unknown> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/setStatuses`;
    return http.put(url, { status: status, orderIds: orderIds });
};

export function getDailyMenus(
    fromDate: Date,
    toDate: Date,
    canteenId: string,
): Promise<AxiosResponse<DailyMenuType[]>> {
    const queryParams = qs.stringify({
        portalId: getPortalId(),
        startDate: getDeviceLanguageDateFormat(fromDate, time_format),
        endDate: getDeviceLanguageDateFormat(toDate, time_format),
    });
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/dailyLunches?${queryParams}`;
    return http.get<DailyMenuType[]>(url);
}

export function updateFoodMenu(
    data: DailyMenuUpdateType[],
    canteenId: string,
): Promise<AxiosResponse<DailyMenuUpdateType[]>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/dailyLunches`;
    return http.put<DailyMenuUpdateType[], AxiosResponse<DailyMenuUpdateType[]>>(url, data);
}

export function getUploadImage(canteenId: string): Promise<AxiosResponse<string>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/dailyLunches/coverImage`;
    return http.get<string>(url);
}
export function uploadImage(image: FormData, canteenId: string): Promise<AxiosResponse<ImageModel[]>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/dailyLunches/coverImage`;
    return http.put<FormData, AxiosResponse<ImageModel[]>>(url, image);
}

export const getCanteenAvailabilities = (menuType: MenuType): AxiosPromise<CanteenAvailability[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/accessibility`;
    return http.get(url, { params: { menuType } });
};

export const updateCanteenAvailabilities = (
    canteenId: string,
    accessEntities: AccessEntitiesType,
    menuType: MenuType,
): AxiosPromise<void> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/accessibility`;
    return http.put(url, { access: accessEntities }, { params: { menuType } });
};

export const getAvailableVendors = (): AxiosPromise<VendorAvailabilityForOrdering[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/vendors/available`;
    return http.get(url);
};

export const getProductsByIdsList = (
    idsList: string[],
    filters: ProductByIdsQueryParams,
): AxiosPromise<ProductV2[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/product/listByIds`;

    return http.post<ProductV2[]>(url, idsList, {
        params: filters,
    });
};

export const getAllVendorsForUser = (): AxiosPromise<UserVendors[]> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/vendors/all`;
    return http.get(url);
};

export const hasExternalIdInCanteen = (canteenId: string): AxiosPromise<boolean> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/hasExternalIdInCanteen`;
    return http.get(url);
};

export const getUserFoodTimeCanteenAvailability = (): AxiosPromise<boolean> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/vendors/hasFoodTimeCanteen`;
    return http.get(url);
};

export const getUserHasAccessToMenusAndDiscounts = (): Promise<AxiosResponse<boolean>> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/hasAccessToMenusAndDiscounts`;
    return http.get(url);
};

export const getUserHasAccessToExternalIdSettings = (): Promise<AxiosResponse<boolean>> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/hasAccessToExternalIdSettings`;
    return http.get(url);
};

export function updateMenuDefaultCanteen(
    canteenId: string,
    isDefault: boolean,
): Promise<AxiosResponse<DailyMenuUpdateType[]>> {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/canteens/${canteenId}/dailyMenu/updateDefault`;
    return http.put<DailyMenuUpdateType[], AxiosResponse<DailyMenuUpdateType[]>>(url, {}, { params: { isDefault } });
}

export const updateOrderPickUpDetails = ({
    orderId,
    pickUpDetails,
}: {
    orderId: string;
    pickUpDetails: PickUpDetails;
}): Promise<AxiosResponse<void>> => {
    return http.patch<void>(
        `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/${orderId}/setPickUp`,
        {},
        { params: { ...pickUpDetails } },
    );
};

export const getAllOrdersByIds = (idsList: string[]): Promise<AxiosResponse<CompanyOrderV2[]>> => {
    const url = `${getBaseUrlApim()}/${urlShop}/portal/${getPortalId()}/orders/list`;
    return http.post<CompanyOrderV2[]>(url, idsList);
};
