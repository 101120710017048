import { ReactElement, useMemo, forwardRef } from 'react';
import type { HtmlImageProps } from 'semantic-ui-react';

export type imageSource = {
    url: string;
    width?: number;
    mime?: string;
};

export type SmartImageProps = Omit<HtmlImageProps, 'src' | 'srcSet'> & { sources: imageSource[] };

const SmartImage = forwardRef<HTMLImageElement, SmartImageProps>(function SmartImageForwarded(
    { sources, style, ...props },
    ref,
): ReactElement {
    const [groupedByMime, all] = useMemo(() => {
        const mimeGroups: Record<string, string[]> = {};
        const all = [];
        for (const { url, width, mime } of sources) {
            const srcSet = `${url} ${width ? `${width}w` : ''}`;
            if (!mimeGroups[mime ?? '']) mimeGroups[mime ?? ''] = [];

            mimeGroups[mime ?? ''].push(srcSet);
            all.push(srcSet);
        }

        return [mimeGroups, all];
    }, [sources]);

    return (
        <picture>
            {Object.entries(groupedByMime).map(([mime, sources]) => (
                <source key={mime} type={mime} srcSet={sources.join(',')} />
            ))}
            <img ref={ref} alt="" style={style} srcSet={all.join(',')} {...(props as HtmlImageProps)} />
        </picture>
    );
});

SmartImage.displayName = 'SmartImage';

export default SmartImage;
