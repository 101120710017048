import { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FoodOrderDetails from '../../FoodForMeetings/FoodOrderDetails';
import PurchasesOverview from '../components/PurchasesOverview/PurchasesOverview';
import { ShopOverviewView } from '../ShopAtWork';
import useShopAtWorkNavigation from '../useShopAtWorkNavigation';

const CompanyPurchases = (): ReactElement => {
    const { getOrderLink } = useShopAtWorkNavigation();
    const purchaseInfoDefaultRoute = `/:portalName${getOrderLink(ShopOverviewView.CompanyPurchases, ':orderId')}`;
    const match = useRouteMatch<{ orderId: string }>(purchaseInfoDefaultRoute);
    const orderId = match?.params.orderId ?? '';
    return (
        <Switch>
            <Route path={`/:portalName${getOrderLink(ShopOverviewView.CompanyPurchases, ':orderId')}`}>
                <FoodOrderDetails view={ShopOverviewView.CompanyPurchases} orderId={orderId} />
            </Route>
            <Route>
                <PurchasesOverview view={ShopOverviewView.CompanyPurchases} />
            </Route>
        </Switch>
    );
};

export default CompanyPurchases;
