import { ReactElement } from 'react';
import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Avatar } from '../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../utility/dateUtilities/getDeviceLanguageDateFormat';

const NameDisplay = styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const EditedByName = styled.span`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
`;

const LastUpdatedText = styled.span`
    font-size: 1rem;
    color: var(--text-medium-emphasis);
`;

interface AuthorDisplayProps {
    user?: { avatar: string; firstName: string; lastName: string };
    updatedDate: Date | null;
    status: string;
    isEditing?: boolean;
    askedForDraft?: boolean;
}
const AuthorDisplay = ({
    user,
    updatedDate,
    status,
    isEditing = false,
    askedForDraft = false,
}: AuthorDisplayProps): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });

    return (
        <NameDisplay>
            {!!user && <Avatar image={user.avatar} firstName={user.firstName} lastName={user.lastName} />}
            <DetailsContainer>
                {!!user && <EditedByName>{[user.firstName, user.lastName].join(' ').trim()}</EditedByName>}
                {isEditing ? null : updatedDate ? (
                    <LastUpdatedText>
                        {askedForDraft || status.startsWith('Draft') || status.startsWith('Archived')
                            ? t('autorArticleInfoUpdated')
                            : isPast(updatedDate)
                            ? t('autorArticleInfoPublished')
                            : t('autorArticleInfoWillBePublished')}
                        {` ${getDeviceLanguageDateFormat(updatedDate, 'Pp')}`}
                    </LastUpdatedText>
                ) : null}
            </DetailsContainer>
        </NameDisplay>
    );
};

export default AuthorDisplay;
