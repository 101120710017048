import { ReactElement, useMemo } from 'react';
import { Menu, Responsive } from 'semantic-ui-react';
import { ModuleName, Module } from '../../../api/apiTypes/portalApiTypes';
import { MobileViewBreakpoint } from '../../ScreenSizeBreakPoints';
import BottomMenu from './BottomMenu';
import { DynamicMenuItem } from '.';

interface SidebarMenuProps {
    handleMenuItemClick(moduleName: string, subModuleName: string): void;
    isSidebarVisible: boolean;
    moduleName: string;
    subModuleName: string;
    modules: Module[];
}
const sortOrder = [
    ModuleName.Dashboard,
    ModuleName.Meeting,
    ModuleName.Food,
    ModuleName.FoodForMeetings,
    ModuleName.Booking,
    ModuleName.FoodExternal,
    ModuleName.Ticketing,
    ModuleName.TicketingExternal,
    ModuleName.Wardrobe,
    ModuleName.ShopAtWork,
    ModuleName.Articles,
];
const SidebarMenu = ({ handleMenuItemClick, modules }: SidebarMenuProps): ReactElement => {
    const sortedSideBarMenuItems = useMemo(
        () =>
            modules.sort((a, b) =>
                a.name === ModuleName.Wayfinding
                    ? 1
                    : b.name === ModuleName.Wayfinding
                    ? -1
                    : sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name),
            ),
        [modules],
    );

    const renderMenu = (): ReactElement => {
        return (
            <>
                <Menu
                    className="sidebar-menu"
                    icon="labeled"
                    vertical
                    borderless
                    style={{
                        display: 'flex',
                        zIndex: 1,
                    }}
                >
                    {sortedSideBarMenuItems.map((module) => (
                        <DynamicMenuItem key={module.id} module={module} />
                    ))}
                </Menu>
                <BottomMenu onClick={handleMenuItemClick} />
            </>
        );
    };

    return (
        <>
            <Responsive
                minWidth={MobileViewBreakpoint}
                className="sidebar-menu-container"
                fireOnMount
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                {renderMenu()}
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="sidebar-menu-container" fireOnMount>
                {renderMenu()}
            </Responsive>
        </>
    );
};

export default SidebarMenu;
